<template>
  <section class="dashboard-users">
    <UsersPanel v-if="hasPermission"> </UsersPanel>
    <InfoBox v-else preset="unauthorized" />
  </section>
</template>

<script>
import UsersPanel from "@/components/access-control/users-panel";
import InfoBox from "@/components/info-box.vue";

export default {
  name: "DashboardUsers",
  components: {
    UsersPanel,
    InfoBox
  },
  computed: {
    hasPermission() {
      return (
        this.$can("manage", "MembroDeContratoAcesso") ||
        this.$can("manage", "AreaDeProcessoAcesso") ||
        this.$can("manage", "GrupoDeUsuariosAcesso")
      );
    }
  },
  mounted() {
    this.$root.$emit("controlSidebar:setContent", null);
    this.$emit("equipmentChanged");
  }
};
</script>

<style scoped>
.dashboard-users {
  /*
    prevent content "jump" when scrollbar is visibile 
    (https://aykevl.nl/2014/09/fix-jumping-scrollbar)
  */
  padding-left: calc(100vw - (100% + 60px));
}
</style>
