<template>
  <div class="container" id="users-panel">
    <div class="box">
      <div class="nav-tabs-custom ">
        <ul class="nav nav-tabs pull-right">
          <li
            :class="{ active: $route.path.endsWith('process-areas') }"
            :title="$tc('process_area', 2)"
            v-if="$can('manage', 'AreaDeProcessoAcesso')"
          >
            <router-link
              to="/dashboard/access-control/process-areas"
              aria-expanded="true"
              data-testid="process-areas-tab"
            >
              <i class="fa fa-tag"></i
              ><span class="hidden-xs"> {{ $tc("process_area", 2) }}</span>
            </router-link>
          </li>
          <li
            :class="{ active: $route.path.endsWith('groups') }"
            :title="$t('groups')"
            v-if="$can('manage', 'GrupoDeUsuariosAcesso')"
          >
            <router-link
              to="/dashboard/access-control/groups"
              aria-expanded="true"
              data-testid="groups-tab"
            >
              <i class="fa fa-users"></i
              ><span class="hidden-xs"> {{ $tc("permission_group", 2) }}</span>
            </router-link>
          </li>
          <li
            :class="{ active: $route.path.includes('privileges') }"
            :title="$t('privileges')"
            v-if="
              $can('manage', 'MembroDeContratoAcesso') &&
                $can('manage', 'MembroDeContratoEscrita') &&
                isNewPrivilegesEnabled
            "
          >
            <router-link
              to="/dashboard/access-control/privileges"
              aria-expanded="true"
              data-testid="privileges-tab"
            >
              <i class="fa fa-sitemap"></i
              ><span class="hidden-xs"> {{ $tc("privileges", 2) }}</span>
            </router-link>
          </li>
          <li
            :class="{ active: $route.path.endsWith('users') }"
            :title="$tc('user', 2)"
            v-if="$can('manage', 'MembroDeContratoAcesso')"
          >
            <router-link
              to="/dashboard/access-control/users"
              aria-expanded="true"
              data-testid="members-tab"
            >
              <i class="fa fa-user"></i
              ><span class="hidden-xs"> {{ $tc("user", 2) }}</span>
            </router-link>
          </li>
          <li class="pull-left header hidden-xs">
            <i class="fa fa-key"></i>
            <span class="hidden-sm">{{ $t("access_control") }} - </span>
            {{ $tc($route.meta.tabTitle, 2) }}
          </li>
        </ul>
        <div class="tab-content panel-content" id="access-control-content">
          <div class="tab-pane active">
            <keep-alive>
              <router-view data-testid="display" @loading="loading" />
            </keep-alive>
          </div>
        </div>
      </div>
      <Spin v-if="busy" />
    </div>
  </div>
</template>
<script>
import Spin from "@/components/spin.vue";
export default {
  name: "UsersPanel",
  components: {
    Spin
  },
  data() {
    return {
      busy: false,
      isNewPrivilegesEnabled: this.$featureToggle.isFeatureEnabled(
        "new-access-control"
      )
    };
  },
  methods: {
    loading(state) {
      this.busy = state;
    },
    handleFeatureUpdate(features) {
      this.isNewPrivilegesEnabled = features["new-access-control"].enabled;
    }
  },
  mounted() {
    this.$featureToggle.addUpdateListener(this.handleFeatureUpdate);
  },
  beforeDestroy() {
    this.$featureToggle.removeUpdateListener(this.handleFeatureUpdate);
  }
};
</script>

<style lang="scss" scoped>
.nav-tabs li:not(.active) {
  cursor: pointer;
}

.nav-tabs-custom {
  height: 88vh;
}

.tab-content.panel-content {
  padding: 2.5em 3rem 0.5rem 3rem;
  overflow: hidden auto;
  height: 92%;

  @media (max-width: 767px) {
    padding: 10px;
  }

  & > .tab-pane,
  & > .tab-pane > * {
    height: 100%;
  }

  #groups-tab,
  #process-areas-tab,
  #members-tab {
    padding-top: 2rem;
  }
}

.box::v-deep .overlay {
  background: rgba(255, 255, 255, 0.7);
}

@media (max-width: 767px) {
  .container {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
<style>
.popover-content {
  font-size: 1.2rem;
}

#access-control-content :is(.entity-search, h4.title) {
  margin-bottom: 2rem;
}
</style>
